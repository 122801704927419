import type { VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import type { ReactNode } from "react";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { MachineSizeInfo } from "./MachineSizeInfo.tsx";
import { VmImageIcon } from "./VmImageIcon.tsx";
import { allAssignedVmsQueryAtom } from "./joinAssignedQuery.ts";
import { extractOsFields } from "./os/os.ts";
import { useOs } from "./os/useOs.ts";
import type { VmCreateInputs } from "./vmCreateViewModel.tsx";
import { getReplicaByUuid } from "./vmEntityUtils.ts";
import { stopVmMutation, type VirtualMachineLoc } from "./vmQuery.ts";

export function ModalHeaderBlock({
    vm,
    children,
    replica,
    showSize,
}: {
    vm: VirtualMachineLoc;
    children?: ReactNode;
    replica?: VirtualMachineStorageReplica;
    showSize: boolean;
}) {
    const { os, title } = useOs(extractOsFields(vm));

    const { name, uuid } = vm;

    return (
        <div className="flex gap-3 items-center justify-between lightBlock">
            <VmImageIcon image={os} className="color-primary size-2.5rem" />
            <div className="flex-grow VStack gap-0.5 children:min-h-1.5em">
                <div>
                    {replica ? (
                        <ClipBoardTooltip text={uuid}>
                            <b>{replica.uuid}</b>
                        </ClipBoardTooltip>
                    ) : (
                        <>
                            <b>{name}</b> - <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                        </>
                    )}
                </div>
                <div className="color-muted">
                    {replica && "Created from "}
                    {title}
                </div>
                {showSize && <MachineSizeInfo vm={vm} />}
            </div>

            {children}
        </div>
    );
}

export function VmCreateModalHeaderBlock({
    inputs,
    children,
}: {
    inputs?: Partial<VmCreateInputs>;
    children?: ReactNode;
}) {
    const vms = useSuspenseQueryAtom(allAssignedVmsQueryAtom);

    if (inputs?.source_replica && inputs.location) {
        const vm = getResourceById(vms, inputs.source_uuid, "virtual_machine");
        const replica = getReplicaByUuid(vm, inputs.source_replica);

        return (
            <ModalHeaderBlock vm={vm} replica={replica} showSize>
                {children}
            </ModalHeaderBlock>
        );
    }
    return <Loading />;
}

export function VmStopModalHeaderBlock({
    vm,
    showStopButton = false,
}: {
    vm: VirtualMachineLoc;
    showStopButton?: boolean;
}) {
    const stopMutation = useStandardMutation(stopVmMutation);
    const { uuid, location, status } = vm;
    return (
        <ModalHeaderBlock vm={vm} showSize>
            {showStopButton && (
                <WButton
                    isDisabled={status === "stopped"}
                    icon="jp-icon-stop"
                    variant="ghost"
                    color="primary"
                    action={async () => {
                        await stopMutation.mutateAsync({ uuid, location, force: false });
                    }}
                >
                    {status === "running" ? "Stop" : "Stopped"}
                </WButton>
            )}
        </ModalHeaderBlock>
    );
}
